<template>
  <section>
    <div class="m-1 mr-4 px-0 proposta">
      <breadcrumb
        title="CCG - Contrato de conta garantia"
        actualPage="Contrato de conta garantia"
      />
    </div>

    <vs-card class="px-4 pb-20 mt-6">
      <h3 class="mt-5">Representantes legais do tomador</h3>

      <div
        class="vx-row mt-8"
        v-for="(representante, index) in listaLepresentantes"
        :key="representante._id"
      >
        <div class="vx-col w-full md:w-1/4">
          <vs-input
            class="w-full"
            label="CPF"
            v-mask="'###.###.###-##'"
            v-model="representante.CPF"
            size="large"
          />
        </div>

        <div class="vx-col w-full md:w-1/3">
          <vs-input
            class="w-full"
            label="Nome"
            v-model="representante.Name"
            size="large"
          />
        </div>

        <div class="vx-col w-full md:w-1/3">
          <vs-input
            class="w-full"
            label="E-mail"
            v-model="representante.Email"
            size="large"
          />
        </div>

        <div class="vx-col w-full md:w-1/12">
          <vs-button
            size="large"
            icon="delete"
            class="mt-6"
            @click="deleteRepresentante(index)"
          ></vs-button>
        </div>
      </div>

      <div class="vx-row mt-3">
        <div class="vx-col w-full">
          <vs-button
            @click="addNewRepresentante()"
            icon-after
            class="font-semibold mt-2 float-right"
            color="primary"
            type="flat"
            icon="add_circle"
          >
            Incluir representante legal
          </vs-button>
        </div>
      </div>
    </vs-card>
  </section>
</template>
<script>
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import { mask } from "vue-the-mask";

export default {
  components: {
    breadcrumb: Breadcrumb
  },
  directives: { mask },
  data() {
    return {
      value1: "",
      listaLepresentantes: [this.createRepresentante()]
    };
  },
  methods: {
    addNewRepresentante() {
      this.listaLepresentantes.push(this.createRepresentante());
    },

    deleteRepresentante(index) {
      this.listaLepresentantes.splice(index, 1);
    },

    createRepresentante() {
      return {
        _id: Math.random(),
        Name: "",
        CPF: "",
        Email: ""
      };
    }
  },
  created() {
    this.$appInsights.trackPageView({
      name: "contrato",
      url: window.location.href
    });
  }
};
</script>
